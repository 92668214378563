:root {
  --bg-primary: #f4f4f4;
  --bg-secondary: #f4f4f4;
  --text-primary: #000000;
  --text-secondary: #444444;
  --text-invert: #ffffff;
  --shadow-primary: 12px 12px 24px 8px rgba(0, 0, 0, 0.16);
  --shadow-secondary: -12px -12px 24px 8px rgba(255, 255, 255, 0.8);
  --shadow-primary: 4px 4px 8px 2px rgba(0, 0, 0, 0.08);
  --shadow-secondary: -4px -4px 8px 2px rgba(255, 255, 255, 0.8);
  --nav-shadow-primary: 4px 4px 8px 2px rgba(0, 0, 0, 0.08);
  --nav-shadow-secondary: -4px -4px 8px 2px rgba(255, 255, 255, 0.8);
  --button-primary: #282828;
  --button-danger: #da0000;
  --button-secondary: #888888;
  --button-secondary-bg: #dddddd;
  --line: #dddddd;
}

.background {
  /*background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);*/
  background: var(--bg-primary);
  min-height: 100vh;
  height: 100% !important;
}

.home-block {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.page-block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
}

.home-card {
  background: var(--bg-primary);
  box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 32px;
  padding: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  max-width: 480px;
}

.tab-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: var(--bg-primary);
  box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  /*max-width: 800px;*/
}

.data-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: var(--bg-primary);
  box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 16px;
  padding: 16px;
  /* margin-left: 8px;
  margin-right: 8px; */
  /* margin-top: 8px; */
  margin-bottom: 16px;
  width: 100%;
  /* overflow: scroll; */
  /*max-width: 800px;*/
}
.device-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  /* background: var(--bg-primary); */
  /* box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 16px; */
  padding: 12px;
  /* margin-top: 8px; */
  /* margin-bottom: 16px; */
  width: 100%;
  /*max-width: 800px;*/
}

.button {
  width: 100%;
  height: 48px;
  margin-top: 10px;
  background-color: var(--button-primary);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-danger {
  width: 100%;
  height: 48px;
  margin-top: 10px;
  background-color: var(--button-danger);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button:hover {
  opacity: 0.85;
  transition: all ease-in 0.25s;
}

.button-border {
  width: 100%;
  height: 48px;
  margin-top: 10px;
  border: solid 1px var(--button-secondary);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-circle {
  width: 60px;
  height: 60px;
  box-shadow: var(--shadow-primary), var(--shadow-secondary);
  border-radius: 60px;
}

.button-circle:hover {
  /*box-shadow: inset 4px 4px 16px rgba(0, 0, 0, 0.08);*/
  transition: all ease-in 0.25s;
}

.button-text {
  color: var(--text-primary);
  font-weight: 600;
}

.button-text-white {
  color: var(--text-invert);
  font-weight: 600;
}

.textfield {
  width: 100%;
  margin-bottom: 20px;
  height: 48px;
}
.textfield-multiple-line {
  width: 100%;
  margin-bottom: 20px;
  /* height: 48px; */
}

.text-title {
  font-size: 28px;
  font-weight: 600;
  color: var(--text-primary);
  margin-top: 10px;
  margin-bottom: 10px;
}
.text-title2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-primary);
  margin-top: 10px;
  margin-bottom: 10px;
}
.text-title-dashboard {
  font-size: 26px;
  font-weight: 600;
  color: var(--text-primary);
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-option {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
  text-align: center;
}

.space-text {
  margin-top: 32px;
  margin-bottom: 32px;
}

.picker-box {
  width: 100%;
  height: 104px;
  border: 2px dashed #cccccc;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 8px;
}

.status-box {
  width: 40px;
  height: 40px;
  /*background: linear-gradient(135deg, #e1edf8 0%, #eef1f3 100%);
  box-shadow: inset -12px -10px 12px rgba(255, 255, 255, 0.76),
    inset 0px 9px 18px #c9d3e2, inset 0px 2px 22px #c8d4e2;
  */
  background: #ffffff;
  box-shadow: inset 8px 8px 8px rgba(0, 0, 0, 0.12);
  border-radius: 40px;
  display: flex;
  /*margin: auto;*/
}

.status-light-red {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: rgb(136, 11, 91);
  background: linear-gradient(
    81deg,
    rgba(136, 11, 91, 1) 21%,
    rgba(203, 41, 71, 1) 100%
  );
  margin: auto;
}

.status-light-orange {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: rgba(202, 116, 2, 1);
  background: linear-gradient(
    81deg,
    rgba(202, 116, 2, 1) 21%,
    rgb(255, 147, 6) 100%
  );
  margin: auto;
}

.status-light-blue {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: rgb(54, 68, 154);
  background: linear-gradient(
    81deg,
    rgba(54, 68, 154, 1) 21%,
    rgba(41, 161, 203, 1) 100%
  );
  margin: auto;
}

.status-light-gray {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: rgb(189, 189, 189);
  background: linear-gradient(
    81deg,
    rgba(189, 189, 189, 1) 21%,
    rgba(235, 235, 235, 1) 100%
  );
  margin: auto;
}

.status-light-green {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: rgb(10, 113, 98);
  background: linear-gradient(
    81deg,
    rgba(10, 113, 98, 1) 0%,
    rgba(83, 112, 13, 1) 100%
  );
  margin: auto;
}

.status-block {
  background: #fafafa;
  /*width: 100%;*/
  height: 64px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.status-block-new {
  background: #fafafa;
  width: 100%;
  /* height: 64px; */
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  /* flex-flow: row wrap; */
  /* flex-wrap: wrap; */
}

.text-status-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  text-align: center;
  /*margin: auto;*/
}
.text-status-title-led {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  text-align: center;
  /*margin: auto;*/
}

.text-status-status {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-secondary);
  text-align: center;
  /*margin: auto;*/
}

.status-show-block {
  background: #fafafa;
  /*width: 100%;*/
  height: 112px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
.status-show-block-car {
  background: #fafafa;
  /*width: 100%;*/
  height: 200px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.text-status-step {
  font-size: 20px;
  font-weight: 600;
  color: #c27a37;
}

.text-status-ref {
  font-size: 20px;
  font-weight: 600;
  color: #53700d;
}

.text-status-temp-1 {
  font-size: 20px;
  font-weight: 600;
  color: #53700d;
}

.text-status-temp-2 {
  font-size: 20px;
  font-weight: 600;
  color: #c27a37;
}

.text-status-temp-3 {
  font-size: 20px;
  font-weight: 600;
  color: #dc143c;
}

.text-status-out {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-secondary);
}

.nav-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 8px;
  /*margin-bottom: 16px;*/
  /*border-bottom: solid 1px var(--line);*/
}

.nav-logo {
  /*width: 50px;*/
  height: 60px;
}

.nav-logo-block {
  /*background: var(--bg-primary);
  box-shadow: var(--nav-shadow-primary), var(--nav-shadow-secondary);*/
  border-radius: 16px;
  padding: 4px;
  width: fit-content;
}

.nav-button-block {
  background: var(--bg-primary);
  box-shadow: var(--nav-shadow-primary), var(--nav-shadow-secondary);
  border-radius: 16px;
  padding: 4px;
  width: fit-content;
}

.nav-button {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  /*margin-top: 10px;
  margin-bottom: 10px;*/
  width: 60px;
  height: 60px;
  /*padding-left: 8px;
  padding-right: 8px;*/
}

.nav-header {
  font-size: 14px;
  font-weight: 800;
  color: var(--text-secondary);
  margin: auto;
}
.popup-card {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  background: var(--bg-primary);
  /* box-shadow: var(--shadow-primary), var(--shadow-secondary); */
  border-radius: 32px;
  padding: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  max-width: 480px;
}
.popup-card2 {
  /*background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);*/
  /* background: var(--bg-primary); */
  /* box-shadow: var(--shadow-primary), var(--shadow-secondary); */
  border-radius: 32px;
  padding: 32px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 480px;
}

.main.chart-wrapper {
  width: 96%;
  height: 400px;
}

.white-block {
  background: #fafafa;
  /*width: 100%;*/
  height: fit-content;
  padding: 8px;
  border-radius: 8px;
  /*margin-bottom: 8px;*/
}

.text-16 {
  font-size: 16px;
}

.text-14 {
  font-size: 14px;
}

.text-12 {
  font-size: 12px;
}

.text-10 {
  font-size: 10px;
}

.b-600 {
  font-weight: 600;
}

.b-500 {
  font-weight: 500;
}

.b-400 {
  font-weight: 400;
}

.t-primary {
  color: var(--text-primary);
}

.t-secondary {
  color: var(--text-secondary);
}

.a-left {
  align-items: flex-start;
  text-align: left;
}

.status-device-online {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: rgb(10, 113, 98);
  background: linear-gradient(
    81deg,
    rgba(10, 113, 98, 1) 0%,
    rgba(83, 112, 13, 1) 100%
  );
  margin: auto;
}
.status-device-fail-online {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: rgb(136, 11, 91);
  background: linear-gradient(
    81deg,
    rgba(136, 11, 91, 1) 21%,
    rgba(203, 41, 71, 1) 100%
  );
  margin: auto;
}
.status-device-offline {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: rgb(189, 189, 189);
  background: linear-gradient(
    81deg,
    rgba(189, 189, 189, 1) 21%,
    rgba(235, 235, 235, 1) 100%
  );
  margin: auto;
}
.status-device-fail-offline {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: rgb(240, 146, 6);
  background: linear-gradient(
    81deg,
    rgba(240, 146, 6, 1) 0%,
    rgba(255, 180, 13, 1) 100%
  );
  margin: auto;
}
